import React, {useContext} from 'react'
import { GlobalDataContext } from "../../context/context";

const BenefitsBlock = () => {

    const { rpdata } = useContext(GlobalDataContext);


    const counterInfo = [
        {
            tittle: 'Cost-Effective & Affordable Prices',
            text: rpdata?.dbAbout?.[3]?.text,
            simbolo: rpdata?.stock?.[25],
        },
        {
            tittle: 'Experienced Professionals',
            text: rpdata?.dbAbout?.[4]?.text,
            simbolo: rpdata?.stock?.[23],
        },
        {
            tittle: 'Customized Solutions',
            text: rpdata?.dbAbout?.[5]?.text ,
            simbolo: rpdata?.stock?.[24],
        },
        {
            tittle: 'High-Quality Materials & Workmanship',
            text: rpdata?.dbAbout?.[6]?.text,
            simbolo: rpdata?.stock?.[26],
        },
    ]

    return (
      <div className='my-16'>
        <h2 className='text-center underline'>
        we have {rpdata?.dbPrincipal?.exprYears} years of experience 
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 m-5">
          {counterInfo.map((items, index) => {
            return (
              <>
                <div
                  key={index}
                  className="justify-center text-center border p-2 bg-[#00000017] rounded adsolute"
                >
                  <div className="">
                    <div className='justify-center px-[40%]'>
                    <img
                      src={items.simbolo}
                      className="text-center w-[100px] items-center"
                      alt="icono"
                    />
                    </div>
                    <h5>{items.tittle}</h5>
                    <p>{items.text}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
}
export default BenefitsBlock
