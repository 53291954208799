import React, {useContext} from 'react'
import { GlobalDataContext } from "../../context/context";

const CallBlock = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <div className="border-2 border-black my-4">
        <div className="bgColor3 justify-center items-center px-4 md:px-32 text-center border-[20px]">
          <h2 className="text-white text-center my-6">
            {rpdata?.dbSlogan?.[6].slogan}
          </h2>

          <button className="bgColor2 justify-center text-center w-52 my-10 p-6 rounded">
            <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}>
              <span className="text-white text-lg font-bold">
                Call Us Today!<br/>
                {rpdata?.dbPrincipal?.phones?.[0]?.phone}
              </span>
            </a>
          </button>
        </div>
      </div>
    );
}
export default CallBlock
